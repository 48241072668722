<template>
  <button
    type="button"
    class="font-main font-medium text-white rounded-full"
    :class="{
      'h-8': size === 'sm',
      'h-10': size === 'base',
      'h-12': size === 'large',

      'px-4': size === 'base' || size === 'sm',
      'px-8': size === 'large',

      'bg-teal': color === 'teal',
      'bg-blue': color === 'blue',
      'bg-gray-300': color === 'gray'
    }"
    v-bind="$attrs"
  >
    <slot />
  </button>
</template>

<script>
  export default {
    props: {
      size: {
        type: String,
        default: 'base'
      },
      color: {
        type: String,
        default: 'teal'
      }
    }
  }
</script>
