<template>
  <section class="inline-block region-modal">
    <ModalPopup
      :show="show"
      classes="s-delivery-modal"
      @close="$emit('close')"
    >
      <template #modal-content>
        <div
          class="s-delivery-modal-container flex flex-col md:flex-row bg-white p-8 md:p-12 mt-14 md:mt-0 gap-10 md:gap-0"
        >
          <div
            class="lg:hidden flex flex-row-reverse absolute right-5 -top-12 md:-top-8 cursor-pointer hover:text-red-500"
            href="#"
            @click="$emit('close')"
          >
            <div>
              <IconCloseModal />
            </div>
          </div>

          <div class="md:w-2/5 w-full text-left">
            <div class="flex flex-row justify-between">
              <a
                class="uppercase text-black text-xs font-bold flex items-center cursor-pointer"
                @click.prevent.self="$emit('prev')"
                ><span><IconCaretLeft class="h-3 w-3 mr-1" /></span> Start Over</a
              >
            </div>

            <div
              v-if="regionID"
              class="mt-3"
            >
              <!-- <span class="text-xs uppercase text-teal">Your Delivery Address</span> -->
              <h1 class="s-region-modal-h1 mt-1 text-black leading-tight underline">
                {{ address }}
              </h1>
            </div>

            <div
              v-if="regionID === null"
              class="region mt-3 md:mt-5"
            >
              <h1 class="s-no-delivery-modal-h1 mt-1 text-black leading-tight underline mb-5">
                Unfortunately, The Flowery doesn't deliver to that address... yet!
              </h1>
              <p class="text-sm text-gray-text mt-1">
                The Flowery is currently expanding to new locations all over the State of Florida and chances are we'll
                be in your area very soon. Please check back on the regular!
              </p>
            </div>

            <div
              v-if="regionID"
              class="region mt-3 md:mt-5"
            >
              <span class="text-xs uppercase text-teal">Your Delivery Location</span>
              <h1 class="text-lg md:text-xl text-pink-400 tracking-wide">
                {{ locationName }}
              </h1>
            </div>

            <div
              v-if="regionID"
              class="region mt-3 md:mt-5"
            >
              <span class="text-xs uppercase text-teal">Delivery Window</span>
              <div>
                <template v-for="(d, i) in deliverySchedule">
                  <span
                    v-if="d.has_delivery"
                    :key="i"
                    class="font-alt font-medium text-lg"
                  >
                    {{ days[d.day_of_week] }}{{ i !== deliverySchedule.length - 1 ? ' /' : '' }}
                  </span>
                </template>
              </div>
              <p class="text-xs text-gray-med mt-2">
                Be sure to place your order by 4:20pm the night before to ensure next day delivery
              </p>
            </div>

            <div
              v-if="regionID"
              class="region mt-3 md:mt-5"
            >
              <span class="text-xs uppercase text-teal">Next Delivery Date</span>
              <h1 class="text-black text-lg tracking-wide">
                {{ nextDeliveryDate }}
              </h1>
            </div>

            <div class="mt-8 md:mt-10 flex flex-col md:flex-row text-left gap-3 justify-start">
              <div v-if="regionID">
                <a
                  href=""
                  class="btn bg-blue text-white px-4 md:px-6 py-3 md:py-4 text-sm inline-flex"
                  @click.prevent="shoppingClicked"
                >
                  Start Shopping
                </a>
                <NuxtLink
                  v-if="!xIsAuthenticated"
                  :to="'/registration'"
                  class="create-link text-xs ml-5"
                >
                  <span class="underline"> Create Account </span>
                </NuxtLink>
              </div>
              <div
                v-else
                class="text-white text-sm inline-flex"
              >
                <button
                  type="buttonx"
                  class="bg-blue px-4 md:px-6 py-3 md:py-4"
                  @click.prevent.self="$emit('prev')"
                >
                  Try Another Address
                </button>
              </div>
            </div>
          </div>
          <div class="md:w-3/5 w-full relative hidden md:block">
            <div class="pl-0 md:pl-16 md:pt-0 -m-5 pr-10">
              <img
                v-if="false"
                style="width: 100%"
                src="@/assets/icon/svg/delivery/new-delivery.png?url"
                alt=""
              />
              <!--  <img
                v-if="regionID === null"
                style="width: 100%"
                src="@/assets/icon/svg/delivery/no-delivery.svg?url"
                alt=""
              />
              <img
                v-if="regionID === 2"
                style="width: 100%"
                src="@/assets/icon/svg/delivery/northeast.svg?url"
                alt=""
              />
              <img
                v-if="regionID === 1566"
                style="width: 100%"
                src="@/assets/icon/svg/delivery/northcentral.svg?url"
                alt=""
              />
              <img v-if="regionID === 1054" style="width: 100%" src="@/assets/icon/svg/delivery/northeast.svg?url" alt="" />
              <img v-if="regionID === 5" style="width: 100%" src="@/assets/icon/svg/delivery/centraleast.svg?url" alt="">
              <img
                v-if="regionID === 6"
                style="width: 100%"
                src="@/assets/icon/svg/delivery/west.svg?url"
                alt=""
              />
              <img
                v-if="regionID === 8"
                style="width: 100%"
                src="@/assets/icon/svg/delivery/southeast.svg?url"
                alt=""
              />
              <img
                v-if="regionID === 9"
                style="width: 100%"
                src="@/assets/icon/svg/delivery/central.svg?url"
                alt=""
              />
              <img
                v-if="regionID === 11"
                style="width: 100%"
                src="@/assets/icon/svg/delivery/keys.svg?url"
                alt=""
              />
              <img
                v-if="regionID === 1683"
                style="width: 100%"
                src="@/assets/icon/svg/delivery/gold-coast.svg?url"
                alt=""
              />
              <img
                v-if="regionID === 1740"
                style="width: 100%"
                src="@/assets/icon/svg/delivery/naturecoastnorth.svg?url"
                alt=""
              />
              <img
                v-if="regionID === 1741"
                style="width: 100%"
                src="@/assets/icon/svg/delivery/naturecoastsouth.svg?url"
                alt=""
              /> -->
              <img
                style="width: 100%"
                src="@/assets/icon/svg/delivery/new-delivery.png?url"
                alt=""
              />
            </div>
            <img
              class="absolute -top-5 -right-5 w-20"
              src="/flowery-logo-stacked.png"
              alt=""
            />
          </div>
        </div>
      </template>
    </ModalPopup>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _address from '@/utils/filters/address'

  import toTwelveHour from '../../store/region/utilities/ToTwelveHour'

  export default {
    name: 'RegionModal',

    props: {
      /** If true we show the modal */
      show: {
        type: Boolean,
        default: false
      },

      showWithError: {
        type: Boolean,
        default: false
      },

      /** The region we are displaying */
      user: {
        type: Object,
        default: null
      },

      /** A function that will return the next available delivery date */
      delivery: {
        type: Function,
        default: (region) => null
      }
    },

    data() {
      return {
        // An index we can use to look up the names of each day that appears in the delivery schedule
        days: {
          0: 'Sunday',
          1: 'Monday',
          2: 'Tuesday',
          3: 'Wednesday',
          4: 'Thursday',
          5: 'Friday',
          6: 'Saturday'
        },

        deliveryWindows: {
          2: 'Monday-Saturday 9AM-6PM',
          5: 'Monday-Thursday 9AM-6PM',
          6: 'Tuesday-Thursday 9AM-6PM',
          8: 'Monday-Wednesday 9AM-6PM',
          9: 'Monday-Thursday 9AM-6PM',
          11: 'Friday 9AM-6PM'
        }
      }
    },

    computed: {
      ...mapGetters('regions', ['xFindNextDeliveryDate']),
      ...mapGetters('authuser', ['xIsAuthenticated']),

      /** Returns the region object for the current user */
      region() {
        return this.user?.getRegion()
      },

      location() {
        return this.user?.getLocation()
      },

      /** Returns the ID of the region object that belongs to the current user */
      regionID() {
        return this.showWithError ? null : this.region?.id || null
      },

      /** Returns the name of the current region */
      regionName() {
        return this.region?.name || 'N/A'
      },

      locationName() {
        return this.location?.name || 'N/A'
      },

      /** Converts the user's currently selected address from object to string */
      address() {
        return _address(this.user?.selectedAddress || null)
      },

      /** Returns the next available delivery date */
      nextDeliveryDate() {
        return this.delivery(this.region)?.human || ''
      },

      /** Returns the delivery window for the current region */
      window() {
        return this.deliveryWindows?.[this.region?.id] || ''
      },

      /** The list of delivery times for the provided region */
      deliverySchedule() {
        return this?.region?.schedule || []
      }
    },

    methods: {
      toTwelveHour,

      shoppingClicked() {
        // ("shopping clicked", this.$route);

        if (this.$route.path !== '/shop') {
          this.$router.push('/shop')
        } else {
          // console.log("already on shop page");
        }

        this.$emit('close')
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-delivery-modal-container {
    @apply font-alt;
    background-color: #fff;
    background-image: url('/modal-bg.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    @apply mx-auto max-w-5xl;

    select {
      background: url('./assets/icon/svg/chevron.svg') no-repeat;
      background-position: calc(100% - 15px) center;
      background-size: 15px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  .s-delivery-modal-step {
    color: #3fb9c5;
    @apply font-alt;
    text-transform: uppercase;
  }

  .s-delivery-modal-h1 {
    color: #000 !important;
    @apply font-main;
    @apply leading-tight font-bold uppercase mb-3 text-2xl md:text-3xl;
  }

  .s-region-modal-h1 {
    color: #000 !important;
    font-size: 1.5rem;
    @apply font-alt;
    @apply leading-tight font-bold mb-3 underline;
  }

  .s-no-delivery-modal-h1 {
    color: #000 !important;
    font-size: 1.5rem;
    @apply font-alt;
    @apply leading-tight font-bold mb-5 underline;
  }

  .s-delivery-buttons-container {
    width: 260px;
  }

  .s-delivery-btn {
    height: 50px;
    border-radius: 25px;
    width: 146px;
    color: #000;
    @apply font-alt;
    border: solid #e5eaed 2px;
  }

  .s-delivery-btn-active {
    background: #002d52;
    color: #fff;
    border: solid #f3b6d0 2px;
    z-index: 10;
  }

  .s-delivery-left-0 {
    left: 0;
  }

  .s-delivery-right-0 {
    right: 0;
  }

  .s-delivery-search-address-svg {
    color: #002d52;
    top: 30%;
  }

  .s-delivery-search-address {
    width: 100%;
    max-width: 375px;
    border-bottom: solid 2px #e5eaed;
    padding-left: 30px;
    @apply font-alt;
    color: #000;
    font-size: 16px;
    @apply py-2;
  }

  .s-delivery-search-address:focus {
    outline: none;
  }

  .s-delivery-search-address::placeholder {
    color: #000;
  }

  .s-delivery-search-result {
    background: #fff;
    color: #000;
    width: 100%;
    max-width: 320px;
    padding: 25px;
    border-radius: 7px;
    border: solid 1px #e5eaed;
    margin: 20px auto auto;
    box-shadow: 0 2px 24px 0 #9ed7f5;
  }

  .s-delivery-search-result-item {
    border-bottom: solid 1px #e7ebee;
    @apply font-alt;
    font-size: 13px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .s-delivery-modal {
    background: #fff;
    max-width: 1042px;
    height: 576px;
    z-index: 1000;
  }

  .create-link {
    color: #4899b9;
  }
</style>
