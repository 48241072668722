<template>
  <section class="delivery-modal">
    <ModalPopup
      :show="show"
      classes="s-delivery-modal"
      @close="$emit('close')"
    >
      <template #modal-content>
        <div
          class="s-delivery-modal-container flex flex-col bg-white pt-8 px-8 md:p-12 mt-0 md:mt-0 gap-10 md:gap-0 relative"
        >
          <div class="h-full flex flex-col md:flex-row md:justify-between">
            <!-- Left hand -->
            <div class="w-full h-full text-left md:w-2/5">
              <template v-if="env.STORE_FEATURES.IN_STORE_PICKUP">
                <h1 class="font-main font-black text-blue text-3xl lg:text-4xl uppercase md:leading-none">
                  We Offer Pick-up And Delivery — Take Your Pick!
                </h1>
                <PickupDeliveryToggle
                  :options="acquisitionLabels"
                  :selected="menuToggleState"
                  class="s-toggle--enlarged my-6"
                  @select-acquisition-option="selectAcquisitionOption"
                />
              </template>
              <template v-else>
                <h1 class="font-main font-black text-blue text-3xl lg:text-4xl uppercase md:leading-none">
                  We Deliver by Region! Let's Find Yours...
                </h1>
                <p class="font-medium text-sm py-6">
                  Enter your address below to find your delivery region so we can provide you with accurate delivery
                  windows and product availability.
                </p>
              </template>

              <template v-if="menuPickupSelected">
                <p class="font-medium text-sm">
                  Pick-up is available right now, same day only. Please check each location for hours and cutoff times.
                </p>
              </template>

              <template v-if="menuDeliverySelected">
                <div class="mt-1 w-full h-full">
                  <!-- UNAUTHENTICATED -->
                  <div class="s-delivery-modal-step font-semibold text-sm">Add A New Address</div>
                  <label class="block my-3 relative">
                    <svg
                      viewBox="0 0 41 41"
                      version="1.1"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      class="w-6 h-6 -mt-2 absolute transform top-[15px]"
                    >
                      <g
                        id="Home-(v2)-NEW"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Home-(100%)"
                          transform="translate(-480.000000, -823.000000)"
                        >
                          <g
                            id="map-pin"
                            transform="translate(480.456311, 823.850000)"
                          >
                            <ellipse
                              id="Oval"
                              fill="#F3B6D0"
                              opacity="0.322883243"
                              cx="19.7912621"
                              cy="19.9125"
                              rx="19.7912621"
                              ry="19.9125"
                            ></ellipse>
                            <g
                              id="20.-Pin"
                              transform="translate(11.728155, 8.850000)"
                              fill="#002D52"
                            >
                              <path
                                d="M8.92123117,1.91651667 C5.23802654,1.91651667 2.32414379,4.550562 2.32414379,8.47582587 C2.32414379,10.7749234 3.27260197,13.2323876 4.57527299,15.4960677 C5.86677971,17.7405212 7.43739398,19.6763371 8.52845073,20.9048162 C8.75021436,21.1537502 9.09224797,21.1537502 9.31350413,20.9048162 C10.4045609,19.6763371 11.9751752,17.7405212 13.2666819,15.4960677 C14.5693529,13.2323876 15.5183185,10.7749234 15.5183185,8.47582587 C15.5183185,4.550562 12.6039283,1.91651667 8.92123117,1.91651667 Z M0,8.8323 C0,3.58425 3.97633333,0 8.7961165,0 C13.615411,0 17.592233,3.58425 17.592233,8.8323 C17.592233,11.6874083 16.4458058,14.51695 15.1,16.8774417 C13.7434434,19.2566167 12.1151845,21.276875 11.0029644,22.5399667 C9.81744337,23.8866417 7.77430097,23.8866417 6.58926861,22.5399667 C5.47704854,21.276875 3.84830097,19.2566167 2.49174434,16.8774417 C1.14593851,14.51695 0,11.6874083 0,8.8323 L0,8.8323 Z"
                              ></path>
                              <path
                                d="M10.7508091,8.85 C10.7508091,9.93609167 9.87559547,10.8166667 8.7961165,10.8166667 C7.71663754,10.8166667 6.84142395,9.93609167 6.84142395,8.85 C6.84142395,7.76390833 7.71663754,6.88333333 8.7961165,6.88333333 C9.87559547,6.88333333 10.7508091,7.76390833 10.7508091,8.85"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <RadarAddressLookup
                      class-name="block w-full s-delivery-search-address placeholder-black w-full"
                      @error="addressError"
                      @created="addressCreated"
                    />
                  </label>

                  <label class="block mb-2 relative">
                    <svg
                      viewBox="0 0 41 41"
                      version="1.1"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      class="w-6 h-6 -mt-2 absolute transform s-delivery-search-address-svg"
                    >
                      <g
                        id="Home-(v2)-NEW"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Home-(100%)"
                          transform="translate(-480.000000, -823.000000)"
                        >
                          <g
                            id="map-pin"
                            transform="translate(480.456311, 823.850000)"
                          >
                            <ellipse
                              id="Oval"
                              fill="#F3B6D0"
                              opacity="0.322883243"
                              cx="19.7912621"
                              cy="19.9125"
                              rx="19.7912621"
                              ry="19.9125"
                            ></ellipse>
                            <g
                              id="20.-Pin"
                              transform="translate(11.728155, 8.850000)"
                              fill="#002D52"
                            >
                              <path
                                d="M8.92123117,1.91651667 C5.23802654,1.91651667 2.32414379,4.550562 2.32414379,8.47582587 C2.32414379,10.7749234 3.27260197,13.2323876 4.57527299,15.4960677 C5.86677971,17.7405212 7.43739398,19.6763371 8.52845073,20.9048162 C8.75021436,21.1537502 9.09224797,21.1537502 9.31350413,20.9048162 C10.4045609,19.6763371 11.9751752,17.7405212 13.2666819,15.4960677 C14.5693529,13.2323876 15.5183185,10.7749234 15.5183185,8.47582587 C15.5183185,4.550562 12.6039283,1.91651667 8.92123117,1.91651667 Z M0,8.8323 C0,3.58425 3.97633333,0 8.7961165,0 C13.615411,0 17.592233,3.58425 17.592233,8.8323 C17.592233,11.6874083 16.4458058,14.51695 15.1,16.8774417 C13.7434434,19.2566167 12.1151845,21.276875 11.0029644,22.5399667 C9.81744337,23.8866417 7.77430097,23.8866417 6.58926861,22.5399667 C5.47704854,21.276875 3.84830097,19.2566167 2.49174434,16.8774417 C1.14593851,14.51695 0,11.6874083 0,8.8323 L0,8.8323 Z"
                              ></path>
                              <path
                                d="M10.7508091,8.85 C10.7508091,9.93609167 9.87559547,10.8166667 8.7961165,10.8166667 C7.71663754,10.8166667 6.84142395,9.93609167 6.84142395,8.85 C6.84142395,7.76390833 7.71663754,6.88333333 8.7961165,6.88333333 C9.87559547,6.88333333 10.7508091,7.76390833 10.7508091,8.85"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <input
                      v-model="apartment"
                      data-lpignore="true"
                      type="text"
                      autocomplete="off"
                      class="block w-full s-delivery-search-address pac-target-input"
                      placeholder="APT or Unit #?"
                    />
                  </label>

                  <SalveButton
                    :disabled="!continueBtnEnabled"
                    :color="continueBtnEnabled ? 'blue' : 'gray'"
                    class="mt-8 text-sm"
                    size="large"
                    @click="showNextModal"
                  >
                    Continue
                  </SalveButton>
                </div>
              </template>
            </div>

            <!-- Right hand Delivery -->
            <div
              v-if="menuDeliverySelected"
              class="hidden md:block w-3/5"
            >
              <div class="pl-0 md:pl-16 md:pt-0 -m-5 relative">
                <img
                  class="pr-10 w-full"
                  src="@/assets/icon/svg/delivery/new-delivery.png?url"
                  alt=""
                />
                <img
                  class="absolute top-0 right-0 w-20"
                  src="/flowery-logo-stacked.png"
                  alt=""
                />
              </div>
            </div>

            <!-- Right hand Pickup -->
            <div
              v-if="menuPickupSelected"
              class="flex h-full mt-6 md:mt-0 md:h-auto md:pr-16 overflow-y-auto"
            >
              <PlaceList
                listing-type="pickup"
                :places="availablePlaces"
                :selected-place="selectedPlace"
                @shop-place="shopPlace"
              >
                <template
                  v-if="!availablePlaces.length"
                  #header
                >
                  <p class="text-base bg-gray-50 border rounded-lg px-4 py-2">
                    {{ $options.salve.lang.noLocationsWithPickup }}
                  </p>
                </template>
              </PlaceList>
            </div>
          </div>
        </div>
      </template>
    </ModalPopup>
  </section>
</template>

<script setup>
  import env from '@/environment'
</script>

<script>
  import { mapActions } from 'vuex'
  import _address from '@/utils/filters/address'
  import LocationSelectionMixin from '@/mixins/LocationSelection'
  import { LANG_NO_LOCATIONS_WITH_PICKUP } from '@/utils/lang'

  export default {
    name: 'DeliveryModal',

    mixins: [LocationSelectionMixin],

    props: {
      /** If true this modal will be rendered */
      show: {
        type: Boolean,
        default: false
      }
    },
    emits: ['created', 'next', 'close', 'error'],
    salve: {
      lang: {
        noLocationsWithPickup: LANG_NO_LOCATIONS_WITH_PICKUP
      }
    },

    _LocationSelectionSettings: {
      initCurrentState: false
    },

    data() {
      return {
        selectedDropdown: null,
        apartment: null,
        continueBtnEnabled: false
      }
    },

    methods: {
      ...mapActions('authuser', ['xAddApartmentToAddress']),

      /** Converts address object to string */
      _address,

      shopPlace(e) {
        this.selectPlace(e)
        this.$emit('close')
        this.$router.push('/shop')
      },

      /** Sends a request to parent component to close this modal and show the next one */
      showNextModal() {
        // Add an apartment number to address if it exists
        if (this.apartment) {
          this.xAddApartmentToAddress(this.apartment)
        }

        if (this.continueBtnEnabled) {
          this.$emit('close')
          this.$router.push('/shop')
        } else {
          this.continueBtnEnabled = false
          this.$emit('next')
        }
      },

      addressCreated() {
        this.continueBtnEnabled = true
        this.$emit('created')
      },

      addressError() {
        this.continueBtnEnabled = false
        this.$emit('error')
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-delivery-modal-container {
    background-color: #fff;
    background-image: url('/modal-bg.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    @apply mx-auto max-w-5xl;

    height: calc(100vh - 12rem);

    @screen md {
      min-width: 833px;
      height: 556px;
    }

    select {
      background: url('@/assets/icon/svg/chevron.svg') no-repeat;
      background-position: calc(100% - 15px) center;
      background-size: 15px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  .s-delivery-modal-step {
    color: #3fb9c5;
    @apply font-alt;
    text-transform: uppercase;
  }

  .s-delivery-modal-h1 {
    color: #000 !important;
    @apply font-main;
    @apply leading-tight font-bold uppercase mb-3 text-2xl md:text-3xl;
  }

  .s-region-modal-h1 {
    color: #000 !important;
    font-size: 1.5rem;
    @apply font-alt;
    @apply leading-tight font-bold mb-3 underline;
  }

  .s-no-delivery-modal-h1 {
    color: #000 !important;
    font-size: 1.5rem;
    @apply font-alt;
    @apply leading-tight font-bold mb-5 underline;
  }

  .s-delivery-buttons-container {
    width: 260px;
  }

  .s-delivery-btn {
    height: 50px;
    border-radius: 25px;
    width: 146px;
    color: #000;
    @apply font-alt;
    border: solid #e5eaed 2px;
  }

  .s-delivery-btn-active {
    background: #002d52;
    color: #fff;
    border: solid #f3b6d0 2px;
    z-index: 10;
  }

  .s-delivery-left-0 {
    left: 0;
  }

  .s-delivery-right-0 {
    right: 0;
  }

  :deep(.s-delivery-search-address-svg) {
    color: #002d52;
    top: 30%;
  }

  :deep(.s-delivery-search-address) {
    width: 100%;
    max-width: 375px;
    border-bottom: solid 2px #e5eaed;
    padding-left: 30px;
    @apply font-alt;
    color: #000;
    font-size: 16px;
    @apply py-2;
  }

  :deep(.s-delivery-search-address:focus) {
    outline: none;
  }

  :deep(.s-delivery-search-address::placeholder) {
    color: #000;
  }

  .s-delivery-search-result {
    background: #fff;
    color: #000;
    width: 100%;
    max-width: 320px;
    padding: 25px;
    border-radius: 7px;
    border: solid 1px #e5eaed;
    margin: 20px auto auto;
    box-shadow: 0 2px 24px 0 #9ed7f5;
  }

  .s-delivery-search-result-item {
    border-bottom: solid 1px #e7ebee;
    @apply font-alt;
    font-size: 13px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .s-delivery-modal {
    background: #fff;
    max-width: 1042px;
    height: 576px;
    z-index: 1000;
  }

  .create-link {
    color: #4899b9;
  }
</style>
