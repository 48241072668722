const DEFAULT_DEBOUNCE_MS = 300
const REQUEST_LIMIT = 5

/**
 * Initializes Radar's autocomplete on a given input element and handles the selected address.
 * @param {Object} radar - The Radar SDK instance.
 * @param {HTMLElement} inputElement - The input element where autocomplete should be applied.
 * @param {Function} onPlaceSelectedCallback - A callback function that handles the selected place.
 * @param {Object} options - Additional options like debounce, limit.
 */
export function radarAutoCompleteHandler(radar, inputElement, onPlaceSelectedCallback, options = {}) {
  if (!radar || !inputElement || typeof onPlaceSelectedCallback !== 'function') {
    console.error('Invalid parameters provided for radarAutoCompleteHandler')
    return
  }

  radar.ui.autocomplete({
    element: inputElement,
    countryCode: 'US',
    onSelection: (place) => {
      onPlaceSelectedCallback(place)
    },
    container: options.container,
    hideResultsOnBlur: true,
    debounceMS: options.debounce || DEFAULT_DEBOUNCE_MS,
    limit: options.limit || REQUEST_LIMIT
  })
}
