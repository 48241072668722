<template>
  <div
    v-show="show"
    :class="getCss"
  >
    <ModalDelivery
      :show="currentModal === 'delivery'"
      @close="closeAllModals"
      @next="openModal('region')"
      @error="handleAddressError"
    />
    <RenderlessXUser v-slot="{ user, xFindNextDeliveryDate }">
      <ModalRegion
        :show="currentModal === 'region'"
        :show-with-error="showRegionModalWithError"
        :user="user"
        :delivery="xFindNextDeliveryDate"
        @close="
          () => {
            showRegionModalWithError = false
            closeAllModals()
          }
        "
        @prev="
          () => {
            showRegionModalWithError = false
            openModal('delivery')
          }
        "
      />
    </RenderlessXUser>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'DeliveryAndRegion',

    props: {
      show: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        currentModal: null,
        showRegionModalWithError: false
      }
    },

    watch: {
      async show() {
        if (this.show) {
          if (this.xTotalCartQuantity === 0) {
            this.currentModal = 'delivery'
          } else {
            try {
              await this.confirmClearCart()
              await this.xClearCart()
              this.currentModal = 'delivery'
            } catch {
              this.SET_CHANGE_MODAL_HANDLER(null)
              this.currentModal = null
              this.$emit('close')
            }
          }
        } else {
          this.currentModal = null
          this.$emit('close')
        }
      }
    },

    computed: {
      ...mapGetters('user-region', ['xSelectedAddress']),
      ...mapGetters('cart', ['xTotalCartQuantity']),

      getCss() {
        if (this.currentModal === null) {
          return []
        }

        return ['modal-flow-opened']
      }
    },

    methods: {
      ...mapActions('cart', ['xClearCart']),
      ...mapMutations('modals', ['SET_CHANGE_MODAL_HANDLER']),

      handleAddressError() {
        this.showRegionModalWithError = true
        this.openModal('region')
      },

      confirmClearCart() {
        return new Promise((resolve, reject) => {
          this.SET_CHANGE_MODAL_HANDLER({
            confirm: () => resolve(),
            cancel: () => reject(new Error('canceled'))
          })
        })
      },
      closeAllModals() {
        this.SET_CHANGE_MODAL_HANDLER(null)
        this.currentModal = null
        this.$emit('close')
      },

      openModal(modal) {
        // console.log("delivery and region opening modal", modal);
        this.currentModal = modal
      }
    }
  }
</script>

<style scoped lang="postcss">
  .modal-flow-opened {
    margin-top: -35px;
  }
</style>
